import React, { useState, useEffect } from "react";
import "./results.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import Switch from '@mui/material/Switch';
import Img1 from "./images/1.png";
import percentIconImg from "./images/percent-icon.jpeg";
import warnicon from "./images/warningicon.jpeg";

import Img2 from "./images/2.png";
import Img3 from "./images/3.png";
import Img4 from "./images/4.png";
import Img5 from "./images/5.png";
import Img6 from "./images/6.png";
import Img7 from "./images/7.png";
import Img8 from "./images/8.png";
import Img9 from "./images/9.png";
// import Img10 from "./images/10.png";
import Img11 from "./images/11.png";
// import Img12 from "./images/12.png";
// import Img13 from "./images/13.png";
import Img14 from "./images/14.png";
import Img15 from "./images/15.png";
import Img16 from "./images/16.png";
import Img17 from "./images/17.png";
import Profile from "./images/profile.jpg";
import { Switch } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";

const Mobile = ({
  userName,
  setUserName,
  titleUser,
  setTitleUser,
  titleCate,
  setTitleCate,
  imgProfileDes,
  setImgProfileDes,
  peopleReach,
  setPeopleReach,
  messaging,
  setMessaging,
  status,
  setStatus,
  goal,
  setGoal,
  amountSpent,
  setAmountSpent,
  duration,
  setDuration,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  createdBy,
  setCreatedBy,
  placements,
  setPlacements,
  audienceName,
  setAudienceName,
  location,
  setLocation,
  age,
  setAge,
  availableBalance,
  setAvailableBalance,
  adBudget,
  setAdBudget,
  estimatedGST,
  setEstimatedGST,
  totalAmount,
  setTotalAmount,
}) => {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  //   const imgUpload = useRef();
  // const [selectedImge, setSelectedImge] = useState();
  //   const [uploadedPreview, setUploadedPreview] = useState();

  // setTitleCate()
  // setTitleUser();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const [selectedFile1, setSelectedFile1] = useState();
  const [preview1, setPreview1] = useState();

  //   const imgUpload = useRef();
  // const [selectedImge, setSelectedImge] = useState();
  //   const [uploadedPreview, setUploadedPreview] = useState();

  // setTitleCate()
  // setTitleUser();

  const onSelectFile1 = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile1(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile1(e.target.files[0]);
  };

  //   const onUploadImage = ()=>{
  //       const file = imgUpload.current.files[0];
  //       const reader = new FileReader();
  //       const url = reader.readAsDataURL(file);
  //       setUploadedPreview(url)
  //   }

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    localStorage.setItem("preview", JSON.stringify(preview));
    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  useEffect(() => {
    if (!selectedFile1) {
      setPreview1(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile1);
    setPreview1(objectUrl);
    localStorage.setItem("profile", JSON.stringify(preview1));
    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile1]);

  return (
    <div>
      <div class="topBar">
        <p>
          <span onClick={() => navigate("/a")}>
            <ArrowBackIcon />
          </span>
          <span onClick={() => navigate("/s")}>Ad Results</span>
        </p>
        <p onClick={() => navigate("/w")}>
          <img src={Img1} style={{ maxWidth: "6.5vw" }} alt="Phoneo" />
        </p>
      </div>

      <div className="profileShare">
        <h4>Ad preview</h4>
        <div className="imageBar">
          <div>
            <div className="imageProfile">
              <div className="SuploadImage">
                <label htmlFor="upload1" className="SuploadFile">
                  {selectedFile1 ? (
                    <img src={preview1} alt="img" />
                  ) : (
                    <AttachmentIcon className="SattachmentIcon" />
                  )}
                  <input
                    type="file"
                    accept="image/jpg,image/jpeg,image/png"
                    id="upload1"
                    onChange={onSelectFile1}
                  />
                </label>
              </div>

              <div>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    // localStorage.setItem("username", e.target.value);
                  }}
                />
                <p>
                  <p>Sponsored</p>
                  <span>•</span>
                  <img src={Img14} style={{ maxWidth: "4.5vw" }} alt="Phoneo" />
                </p>
              </div>
            </div>
            <div className="imageDes">
              <input
                type="text"
                value={imgProfileDes}
                onChange={(e) => {
                  setImgProfileDes(e.target.value);
                  // localStorage.setItem("userdes", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="uploadImage">
            <label htmlFor="upload" className="uploadFile">
              {selectedFile ? (
                <img src={preview} alt="" />
              ) : (
                <AttachmentIcon className="attachmentIcon" />
              )}
              <input
                type="file"
                accept="image/jpg,image/jpeg,image/png"
                id="upload"
                onChange={onSelectFile}
              />
            </label>
          </div>
          <div style={{ borderTop: "1.5px solid rgba(128, 128, 128, 0.244)" }}>
            <div className="imageBottomTop">
              <div>
                <input
                  type="text"
                  value={titleUser}
                  onChange={(e) => {
                    setTitleUser(e.target.value);
                    // localStorage.setItem("usertitle", e.target.value);
                  }}
                />
                <input
                  type="text"
                  className="titleCate"
                  value={titleCate}
                  onChange={(e) => {
                    setTitleCate(e.target.value);
                    // localStorage.setItem("usercate", e.target.value);
                  }}
                />
              </div>
              <div>
                <button>
                  <span>
                    <WhatsAppIcon />
                  </span>
                  <span>WhatsApp</span>
                </button>
              </div>
            </div>
            <div className="imageBottom">
              <div>
                <img src={Img15} style={{ maxWidth: "6vw" }} alt="Phoneo" />
                <p>Like</p>
              </div>
              <div>
                <img src={Img16} style={{ maxWidth: "6vw" }} alt="Phoneo" />
                <p>Comment</p>
              </div>
              <div>
                <img src={Img17} style={{ maxWidth: "6vw" }} alt="Phoneo" />
                <p>Share</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="middleBar">
        <div>
          <p>Results From This Ad</p>
          <p>
            <img src={Img2} style={{ maxWidth: "6.2vw" }} alt="Phoneo" />
          </p>
        </div>
        <div className="viewsBar">
          <div>
            <p>People reached</p>
            <input
              type="text"
              value={peopleReach}
              onChange={(e) => {
                setPeopleReach(e.target.value);
                // localStorage.setItem("peoplereach", e.target.value);
              }}
            />
          </div>
          <div>
            <p>messaging conversations started</p>
            <input
              type="text"
              value={messaging}
              onChange={(e) => {
                setMessaging(e.target.value);
                // localStorage.setItem("masseging", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="help_us_container">
          <div>
            <p>Help Us Improve</p>
            <span>Are you satisfied with this ad?</span>
            {/* <input type="text" value={peopleReach} onChange={(e)=>setPeopleReach(e.target.value)} /> */}
          </div>
          {/* <div>
            <p>messaging conversations started</p>
            <input type="text" value={messaging} onChange={(e)=>setMessaging(e.target.value)} />
          </div> */}
          <div>
            <button>No</button>
            <button>Yes</button>
          </div>
        </div>
      </div>

      <div className="details">
        <h4>Details</h4>
        <div>
          <div>
            <img src={Img3} style={{ maxWidth: "5.5vw" }} alt="Phoneo" />
          </div>
          <div>
            <p>Status</p>
            <input
              type="text"
              value={status}
              style={
                status === "Not delivering"
                  ? { color: "red" }
                  : { color: "green" }
              }
              onChange={(e) => {
                setStatus(e.target.value);
                // localStorage.setItem("status", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img4} style={{ maxWidth: "5.3vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Goal</p>
            <input
              type="text"
              value={goal}
              onChange={(e) => {
                setGoal(e.target.value);
                // localStorage.setItem("goal", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img5} style={{ maxWidth: "5.5vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Amount Spent</p>
            <input
              type="text"
              value={amountSpent}
              onChange={(e) => {
                setAmountSpent(e.target.value);
                // localStorage.setItem("amountspent", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img6} style={{ maxWidth: "5.3vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Duration</p>
            <input
              type="text"
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value);
                // localStorage.setItem("duration", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img7} style={{ maxWidth: "5vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Start Date</p>
            <input
              type="text"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                // localStorage.setItem("startdate", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img7} style={{ maxWidth: "5vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>End Date</p>
            <input
              type="text"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                // localStorage.setItem("enddate", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img8} style={{ maxWidth: "4.3vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Created By</p>
            <input
              type="text"
              value={createdBy}
              onChange={(e) => {
                setCreatedBy(e.target.value);
                // localStorage.setItem("createdby", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <img src={Img9} style={{ maxWidth: "5.5vw" }} alt="Phoneo" />
          </div>
          <div className="borderText">
            <p>Placements</p>
            <input
              type="text"
              value={placements}
              onChange={(e) => {
                setPlacements(e.target.value);
                // localStorage.setItem("placement", e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <button>Boost Again</button>
          <button>Boost a Different Post</button>
        </div>
      </div>
      <div className="AdvantageCreative">
        <div>
          <p>Advantage+Creative</p>
          <img src={warnicon} alt="img" />
        </div>
        <div>
          <p>
            Leverage Facebook's data to automatically deliver different ad
            creative variations to people when likely to improve performance.
          </p>
          <p>
            <Switch size="small" defaultChecked />
          </p>
        </div>
      </div>
      <div className="SpecialadCategory">
        <div>
          <p>Special ad Category</p>
          <img src={warnicon} alt="img" />
        </div>
        <div>
          <p>
            Ads about credit, employment, housing, or social issues, elections
            or politics
          </p>
          <p>
            <Switch size="small" disabled checked={false} />
          </p>
        </div>
      </div>

      <div className="audienceDetails">
        <h4>Audience Details</h4>
        {/* <div className="improvementAd">
          <p>
            <img src={Img10} style={{ maxWidth: "5vw" }} alt="Phoneo" />
            <span>Improvement to ad delivery</span>
          </p>
          <p>
            <KeyboardArrowDownRoundedIcon />
          </p>
        </div> */}
        <div className="audienceDetails-text audienceDetails-border">
          <p>Audience Name</p>
          <input
            type="text"
            value={audienceName}
            onChange={(e) => {
              setAudienceName(e.target.value);
              // localStorage.setItem("audiencename", e.target.value);
            }}
          />
        </div>
        <div className="audienceDetails-text audienceDetails-border">
          <p>Location - living in</p>
          <textarea
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
              // localStorage.setItem("location", e.target.value);
            }}
          ></textarea>
        </div>
        <div className="audienceDetails-text audienceDetails-border">
          <p>Age</p>
          <input
            type="text"
            value={age}
            onChange={(e) => {
              setAge(e.target.value);
              // localStorage.setItem("age", e.target.value);
            }}
          />
        </div>
        {/* <div className="audienceDetails-text">
          <p>Gender</p>
          <input type="text" value={gender} onChange={(e)=>setGender(e.target.value)} />

        </div> */}
        {/* <div>
          <button>Edit Audience</button>
        </div> */}
      </div>

      <div className="reachPeople">
        <h4>Reach More People</h4>

        <div className="issuesAd">
          <div>
            <div>
              <img src={Img11} style={{ maxWidth: "5vw" }} alt="Phoneo" />
            </div>
            <div className="issuesText">
              <h4>There May Be Issues With This Ad</h4>
              <p>
                This budget exceeds your account spend limit. Reset the amount
                spent and we'll let you know when you reach your limit again.
                <span>Learn More</span>
              </p>
            </div>
            <div>
              <KeyboardArrowUpRoundedIcon />
            </div>
          </div>
          <div>
            <button>Reset amount spent</button>
          </div>
        </div>
      </div>

      {/* <div className="placementsDetails">
        <div className="placementsBar">
          <div>
            <h4>Placements</h4>
            <p>
              Choose where your ad will appear. More platforms help improOve
              results.
            </p>
          </div>
          <div>
            <img src={Img2} style={{ maxWidth: "6.2vw" }} alt="Phoneo" />
          </div>
        </div>

        <div className="placementsSocial">
          <div>
            <div>
              <p>
                <img src={Img12} style={{ maxWidth: "6.2vw" }} alt="Phoneo" />
              </p>
              <p>Facebook</p>
            </div>
            <div>
              <input type="checkbox" checked disabled />
            </div>
          </div>
          <div>
            <div>
              <p>
                <img src={Img13} style={{ maxWidth: "6.2vw" }} alt="Phoneo" />
              </p>
              <p>Messager</p>
            </div>
            <div>
              <input type="checkbox" disabled />
            </div>
          </div>

          <h4>Increase ad exposure with Multi-advertiser Ads</h4>

          <div className="publicTerm">
            <p>
              Allow Meta to help increase your ad exposure by displaying your ad
              alongside ads from multiple businesses.{" "}
              <span style={{ color: "#155cba" }}>Learn More</span>
            </p>
            <p>
              <Switch size="small" defaultChecked />
            </p>
          </div>
        </div>
      </div> */}

      <div className="payment">
        <h4>Payment Method</h4>
        <div>
          <div>
            <p>
              <img src={percentIconImg} alt="img" />
              <span>Ad credits: </span>:
              <input
                type="text"
                value={availableBalance}
                onChange={(e) => {
                  setAvailableBalance(e.target.value);
                  // localStorage.setItem("availablebalance", e.target.value);
                }}
              />
            </p>
            {/* <input type="text" value={availableBalance} onChange={(e)=>setAvailableBalance(e.target.value)} /> */}
          </div>
        </div>
        <p>
          If you spend all your ad credits, we we'll use your{" "}
          <input
            style={{ width: "18%" }}
            type="text"
            value={availableBalance}
            onChange={(e) => {
              setAvailableBalance(e.target.value);
              // localStorage.setItem("availablebalance", e.target.value);
            }}
          />
          in prepaid fund{" "}
          <span style={{ color: "blue", fontSize: "12px" }}>Add funds</span>
        </p>
      </div>

      <div className="amountSpent">
        <h4>Amount Spent</h4>
        <div className="total">
          <div>
            <p>Ad Budget</p>
            <input
              type="text"
              value={adBudget}
              onChange={(e) => {
                setAdBudget(e.target.value);
                // localStorage.setItem("adbudget", e.target.value);
              }}
            />
          </div>
          <div>
            <p>Estimated GST (18%)</p>
            <input
              type="text"
              value={estimatedGST}
              onChange={(e) => {
                setEstimatedGST(e.target.value);
                // localStorage.setItem("estimatedGST", e.target.value);
              }}
            />
          </div>
          <div>
            <p>Total Amount</p>
            <input
              type="text"
              value={totalAmount}
              onChange={(e) => {
                setTotalAmount(e.target.value);
                // localStorage.setItem("totalamount", e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <p>
            Need to report a problem ? <span>Give feedback</span>
          </p>
        </div>
      </div>

      <div className="bottomBar">
        <button disabled>Save Changes</button>
        <p>
          By tapping Save Changes you agree to{" "}
          <span>Meta's Terms and condition</span>
        </p>
      </div>
    </div>
  );
};

export default Mobile;
