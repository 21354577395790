import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import "./website.css";
import Img35 from "./images/35.png";
import Img34 from "./images/34.png";
import Img33 from "./images/33.png";
import Img26 from "./images/26.png";
import Img27 from "./images/27.png";
import Img28 from "./images/28.png";
import Img29 from "./images/29.png";
import Img30 from "./images/30.png";
import Img31 from "./images/31.png";
import Img20 from "./images/20.png";

const Website = ({
  AdsName,
  setAdsName,
  AdsStatus,
  setAdsStatus,
  totalAmount,
  setTotalAmount,
  SpendingLimit,
  setSpendingLimit,
  NewAwareness,
  setNewAwareness,
  Reach,
  setReach,
  PeopleCost,
  setPeopleCost,
  amountSpent,
  setAmountSpent,
  Maximum,
  setMaximum,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  const [AddList, setAddList] = useState(1);
  const [btn, setBtn] = useState(false);

  return (
    <>
      <div className="setting_box">
        <div className="setting_top">
          <div className="setting_up">
            <div className="setting_up_setup">
              <div className="setting_up_box">
                <img src={Img26} alt="" className="setting_img" />
              </div>
            </div>
            <div className="setting_up_text_box">
              <input
                type="text"
                value={AdsName}
                onChange={(e) => {
                  setAdsName(e.target.value);
                  // localStorage.setItem("username");
                }}
                className="setting_up_text1"
              />
              <div className="setting_up_des">
                <FiberManualRecordIcon
                  style={{
                    fontSize: "8px",
                    marginRight: "5px",
                    color: "rgb(34, 194, 50)",
                  }}
                />
                <input
                  type="text"
                  value={AdsStatus}
                  onChange={(e) => {
                    setAdsStatus(e.target.value);
                    // localStorage.setItem("Ads_Status");
                  }}
                  className="setting_up_text2"
                />
              </div>
            </div>
            <div className="setting_up_icon_box">
              <div className="setting_up_box_first">
                <img src={Img27} alt="" className="setting_img" />
              </div>
              <div
                className="setting_up_box_last"
                onClick={() => setAddList(AddList + 1)}
              >
                <img src={Img28} alt="" className="setting_img" />
              </div>
            </div>
          </div>

          <div className="setting_amount">
            <div className="setting_amount_box">
              <p className="setting_amount_title">Total amount spent</p>
              <input
                type="text"
                value={totalAmount}
                onChange={(e) => {
                  setTotalAmount(e.target.value);
                  // localStorage.setItem("total_amount");
                }}
                className="setting_amount_title_input1"
              />
            </div>
            <div className="setting_amount_box">
              <p className="setting_amount_title">Spending limit</p>
              <input
                type="text"
                value={SpendingLimit}
                onChange={(e) => {
                  setSpendingLimit(e.target.value);
                  // localStorage.setItem("spendinglimit");
                }}
                className="setting_amount_title_input2"
              />
            </div>
            <div className="setting_amount_box">
              <NavigateNextOutlinedIcon className="setting_amount_icon" />
            </div>
          </div>

          <div className="setting_campaigns">
            <div className="setting_campaigns_top">
              <div className="setting_campaigns_title">Campaign</div>
              <div className="setting_campaigns_icon_box">
                <div className="setting_box_first">
                  <img src={Img29} alt="" className="setting_up_img" />
                </div>
                <div className="setting_box_first">
                  <img src={Img30} alt="" className="setting_up_img" />
                </div>
              </div>
            </div>
            <div className="setting_campaigns_bottom">
              <div className="setting_campaigns_bottom_btn">
                <input
                  type="text"
                  value={Maximum}
                  onChange={(e) => setMaximum(e.target.value)}
                  className="maximumDate"
                />
              </div>
              <div className="setting_campaigns_bottom_btn">
                <img
                  src={Img31}
                  alt=""
                  className="setting_campaigns_bottom_img"
                />
                <span style={{ marginTop: "4px" }}> Filter</span>
              </div>
            </div>
          </div>

          {Array.apply(null, Array(AddList)).map((i, index) => (
            <div className="do_list">
              <div className="do_list_box">
                <div className="do_list_box_top">
                  <span className="do_list_box_top_off">
                    <FiberManualRecordIcon className="do_list_box_top_icon" />
                    <span onClick={() => setBtn(!btn)}>
                      {btn ? "Active" : "Off"}
                    </span>
                  </span>
                  <img src={Img20} alt="" className="do_list_box_top_img" />
                </div>
                <div className="do_list_box_middle">
                  <div className="SuploadImage">
                    <label htmlFor="upload" className="SuploadFile">
                      {selectedFile ? (
                        <img src={preview} alt="img" />
                      ) : (
                        <AttachmentIcon className="SattachmentIcon" />
                      )}
                      <input
                        type="file"
                        accept="image/jpg,image/jpeg,image/png"
                        id="upload"
                        onChange={onSelectFile}
                      />
                    </label>
                  </div>
                  <div style={{ width: "80%", marginLeft: "5px" }}>
                    <input
                      type="text"
                      value={NewAwareness}
                      onChange={(e) => {
                        setNewAwareness(e.target.value);
                        // localStorage.setItem("newawareness");
                      }}
                      className="do_list_box_top_input"
                    />
                  </div>
                </div>
                <div className="do_list_box_bottom">
                  <div className="do_list_box_bottom_bar">
                    <input
                      type="text"
                      value={Reach}
                      onChange={(e) => {
                        setReach(e.target.value);
                        // localStorage.setItem("peoplereach");
                      }}
                      className="do_list_box_bottom_bar_input"
                    />
                    <span className="do_list_box_bottom_bar_text">Reach</span>
                  </div>
                  <div className="do_list_box_bottom_bar">
                    <input
                      type="text"
                      value={PeopleCost}
                      onChange={(e) => setPeopleCost(e.target.value)}
                      className="do_list_box_bottom_bar_input"
                    />
                    <span className="do_list_box_bottom_bar_text">
                      new message conversations
                    </span>
                  </div>
                  <div className="do_list_box_bottom_bar">
                    <input
                      type="text"
                      value={amountSpent}
                      onChange={(e) => {
                        setAmountSpent(e.target.value);
                        // localStorage.setItem("amountspent");
                      }}
                      className="do_list_box_bottom_bar_input"
                    />
                    <span className="do_list_box_bottom_bar_text">
                      amount spent
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="setting_tab">
          <div className="setting_tab_btn">
            <img src={Img35} alt="" className="setting_tab_icon" />
          </div>
          <div className="setting_tab_btn">
            <img src={Img33} alt="" className="setting_tab_icon" />
          </div>
          <div className="setting_tab_btn">
            <img src={Img34} alt="" className="setting_tab_icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Website;
