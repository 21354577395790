import React,{useState,useEffect} from 'react';
import './App.css';
import Mobile from './Mobile';
import SecondPage from './SecondPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Website from './Website';
import Ads from './Ads';

function App() {

  
  const user_name = localStorage.getItem('username');
  const user_des = localStorage.getItem('userdes');
  const user_title = localStorage.getItem('usertitle');
  const user_cate = localStorage.getItem('usercate');
  const people_reach = localStorage.getItem('peoplereach');
  const masseging = localStorage.getItem('masseging');
  const status_des = localStorage.getItem('status');
  const goal_des = localStorage.getItem('goal');
  const amount_spent = localStorage.getItem('amountspent');
  const duration_des = localStorage.getItem('duration');
  const start_date = localStorage.getItem('startdate');
  const end_date = localStorage.getItem('enddate');
  const created_by = localStorage.getItem('createdby');
  const placement_des = localStorage.getItem('placement');
  const audience_name = localStorage.getItem('audiencename');
  const location_des = localStorage.getItem('location');
  const age_des = localStorage.getItem('age');
  const available_balance = localStorage.getItem('availablebalance');
  const ad_budget = localStorage.getItem('adbudget');
  const estimated_GST = localStorage.getItem('estimatedGST');
  const total_amount = localStorage.getItem('total_amount');
  const profile_time = localStorage.getItem("time");
  const profile_times = localStorage.getItem("times");
  const people_cost = localStorage.getItem("peoplecost");
  // const profile_status = localStorage.getItem("profilestatus");
  const daily_budegt = localStorage.getItem("dailybudegt");
  const impressions = localStorage.getItem("impressions");
  const unique_clicks = localStorage.getItem("uniqueclicks");
  const C_P_C = localStorage.getItem("CPC");
  const campaign_name = localStorage.getItem("campaignname");
  const new_awareness = localStorage.getItem("newawareness");
  const new_awareness_add = localStorage.getItem("newawarenessadd");
  const new_awareness_des = localStorage.getItem("newawarenessdes");
  const maximum = localStorage.getItem("maximum");
  const campaign_status = localStorage.getItem("campaignstatus");
  const Ads_Status = localStorage.getItem("AdsStatus");
  const spending_limit = localStorage.getItem("spendinglimit");
  const advertis_count = localStorage.getItem("advertiscount");
  const advertis_time = localStorage.getItem("advertistime");
  const post_engagements = localStorage.getItem("postengagements");
  const link_clicks = localStorage.getItem("linkclicks");
  const img_title = localStorage.getItem("imgtitle");
  const img_des = localStorage.getItem("imgdes");  
  const purchase_ROAS = localStorage.getItem("roas");  
  


  useEffect(()=>{
    localStorage.setItem('username',userName);
    localStorage.setItem('userdes',imgProfileDes);
    localStorage.setItem('usertitle',titleUser);
    localStorage.setItem('usercate',titleCate);
    localStorage.setItem('peoplereach',peopleReach);
    localStorage.setItem('masseging',messaging);
    localStorage.setItem('status',status);
    localStorage.setItem('goal',goal);
    localStorage.setItem('amountspent',amountSpent);
    localStorage.setItem('duration',duration);
    localStorage.setItem('startdate',startDate);
    localStorage.setItem('enddate',endDate);
    localStorage.setItem('createdby',createdBy);
    localStorage.setItem('placement',placements);
    localStorage.setItem('audiencename',audienceName);
    localStorage.setItem('location',location);
    localStorage.setItem('age',age);
    localStorage.setItem('availablebalance',available_balance);
    localStorage.setItem('adbudget',adBudget);
    localStorage.setItem('estimatedGST',estimatedGST);
    localStorage.setItem('total_amount',totalAmount);
    localStorage.setItem('time',Time);
    localStorage.setItem('times',Times);
    localStorage.setItem('peoplecost',PeopleCost);
    localStorage.setItem('dailybudegt',DailyBudegt);
    localStorage.setItem('impressions',Impressions);
    localStorage.setItem('uniqueclicks',UniqueClicks);
    localStorage.setItem('CPC',CPC);
    localStorage.setItem('campaignname',CampaignName);
    localStorage.setItem('newawareness',NewAwareness);
    localStorage.setItem('newawarenessadd',NewAwarenessAdd);
    localStorage.setItem('newawarenessdes',NewAwarenessDes);
    localStorage.setItem('maximum',Maximum);
    localStorage.setItem('campaignstatus',CampaignStatus);
    localStorage.setItem('AdsStatus',AdsStatus);
    localStorage.setItem('spendinglimit',SpendingLimit);
    localStorage.setItem('advertiscount',AdvertisCount);
    localStorage.setItem('advertistime',AdvertisTime);
    localStorage.setItem('postengagements',PostEngagements);
    localStorage.setItem('linkclicks',LinkClicks);
    localStorage.setItem('imgtitle',ImgTitle);
    localStorage.setItem('imgdes',ImgDes);
    localStorage.setItem('roas',PurchaseROAS);
  },[])




  const [userName, setUserName] = useState(user_name===null?"Mobiles 24":user_name);
  const [titleUser, setTitleUser] = useState(user_title===null?"Mobiles 24":user_title);
  const [titleCate, setTitleCate] = useState(user_cate===null?"Phone/Tablet":user_cate);
  const [imgProfileDes, setImgProfileDes] = useState(user_des===null?"We Believe In Quality... Mobiles 24":user_des);
  const [peopleReach, setPeopleReach] = useState(people_reach===null?"4,190":people_reach);
  const [messaging, setMessaging] = useState(masseging===null?"90":masseging);
  const [status, setStatus] = useState(status_des===null?"Not delivering":status_des);
  const [goal, setGoal] = useState(goal_des===null?"Get more messages":goal_des);
  const [amountSpent, setAmountSpent] = useState(amount_spent===null?"₹932.20":amount_spent);
  const [duration, setDuration] = useState(duration_des===null?"91 days":duration_des);
  const [startDate, setStartDate] = useState(start_date===null?"Mar 31, 2023 1:55pm":start_date);
  const [endDate, setEndDate] = useState(end_date===null?"Jun 30, 2023 1:55pm":end_date);
  const [createdBy, setCreatedBy] = useState(created_by===null?"Komal Bin":created_by);
  const [placements, setPlacements] = useState(placement_des===null?"FaceBook":placement_des);
  const [audienceName, setAudienceName] = useState(audience_name===null?
    "People you choose through targeting":audience_name
  );
  const [location, setLocation] = useState(location_des===null?
    "India: Maharashtra; Karnataka; Rajasthan; Madhya Pradesh":location_des
  );
  const [age, setAge] = useState(age_des===null?"20 - 40":age_des);
  // const [gender, setGender] = useState("Male");
  const [availableBalance, setAvailableBalance] = useState(available_balance===null?"₹100.00":available_balance);
  // const [addMoney, setAddMoney] = useState("₹100.00");
  const [adBudget, setAdBudget] = useState(ad_budget==null?"₹932.20":ad_budget);
  const [estimatedGST, setEstimatedGST] = useState(estimated_GST===null?"₹167.80":estimated_GST);
  const [totalAmount, setTotalAmount] = useState(total_amount===null?"₹167.80":total_amount);
  const [AdvertisName, setAdvertisName] = useState(
    user_name === null ? "Phoneo in" : user_name
  );
  const [AdvertisPrice, setAdvertisPrice] = useState(
    amount_spent === null ? "₹0" : amount_spent
  );
  const [AdvertisCount, setAdvertisCount] = useState(
    advertis_count === null ? "1" : advertis_count
  );
  const [AdvertisTime, setAdvertisTime] = useState(advertis_time===null?"last 60 days":advertis_time);
  const [PostEngagements, setPostEngagements] = useState(post_engagements===null?"--":post_engagements);
  const [LinkClicks, setLinkClicks] = useState(link_clicks===null?"--":link_clicks);
  const [Time, setTime] = useState(profile_time===null?"oct 2023":profile_time);
  const [Times, setTimes] = useState(profile_times===null?"oct 2023":profile_times);
  const [ImgTitle, setImgTitle] = useState(img_title===null?"video views":img_title);
  const [MesssagingConversations, setMesssagingConversations] = useState(masseging===null?"--":masseging);
  const [Spent, setSpent] = useState(amount_spent===null?"90.00":amount_spent);
  const [AdsName,setAdsName] = useState(user_name===null?"Phoneo In":user_name);
  const [AdsStatus,setAdsStatus] = useState(Ads_Status===null?"No active compalons":Ads_Status);
  const [SpendingLimit,setSpendingLimit] = useState(spending_limit===null?"₹30.83 remaining":spending_limit);
  const [NewAwareness, setNewAwareness] = useState(new_awareness===null?"New Awareness campaign":new_awareness);
  const [Reach,setReach] = useState(people_reach===null?"₹14,107":people_reach);
  const [PeopleCost,setPeopleCost] = useState(people_cost===null?"14,107":people_cost);
  const [Maximum,setMaximum] = useState(maximum===null?"Maximum":maximum);

  const [CostPerPeople, setCostPerPeople] = useState(
    people_cost === null ? "₹155" : people_cost
  );
 
  const [DailyBudegt, setDailyBudegt] = useState(
    daily_budegt === null ? "₹155.00" : daily_budegt
  );

  const [Impressions, setImpressions] = useState(
    impressions === null ? "155" : impressions
  );
  const [UniqueClicks, setUniqueClicks] = useState(
    unique_clicks === null ? "155" : unique_clicks
  );
  const [CPC, setCPC] = useState(C_P_C === null ? "155" : C_P_C);
  const [MessagingConversations, setMessagingConversations] = useState(
    masseging === null ? "155" : masseging
  );

  const [CampaignName, setCampaignName] = useState(
    campaign_name === null ? "Campaign name" : campaign_name
  );
  const [NewAwarenessAdd, setNewAwarenessAdd] = useState(new_awareness_add===null?"New Awareness Add":new_awareness_add);
  const [NewAwarenessDes, setNewAwarenessDes] = useState(new_awareness_des===null?"Image":new_awareness_des);

  const [BudgetSetup, setBudgetSetup] = useState(ad_budget===null?
    "₹500.00":ad_budget
  );
  const [CampaignStatus, setCampaignStatus] = useState(campaign_status===null?
    "Campaign off":campaign_status
  );
  const [ImgDes, setImgDes] = useState(img_des===null?"Boosted Facehonk reel":img_des);
  const [PurchaseROAS, setPurchaseROAS] = useState(purchase_ROAS===null?"0.00":purchase_ROAS);



  const router = createBrowserRouter([
    {
      path: "/",
      element: <Mobile 
      userName={userName}
  setUserName={setUserName}
  titleUser = {titleUser}
  setTitleUser = {setTitleUser}
  titleCate = {titleCate}
  setTitleCate = {setTitleCate}
  imgProfileDes = {imgProfileDes}
  setImgProfileDes = {setImgProfileDes}
  peopleReach = {peopleReach}
  setPeopleReach = {setPeopleReach}
  messaging = {messaging}
  setMessaging = {setMessaging}
  status = {status}
  setStatus = {setStatus}
  goal = {goal}
  setGoal = {setGoal}
  amountSpent = {amountSpent}
  setAmountSpent = {setAmountSpent}
  duration = {duration}
  setDuration = {setDuration}
  startDate = {startDate}
  setStartDate = {setStartDate}
  endDate = {endDate}
  setEndDate = {setEndDate}
  createdBy = {createdBy}
  setCreatedBy = {setCreatedBy}
  placements = {placements}
  setPlacements = {setPlacements}
  audienceName = {audienceName}
  setAudienceName = {setAudienceName}
  location = {location}
  setLocation = {setLocation}
  age = {age}
  setAge = {setAge}
  availableBalance = {availableBalance}
  setAvailableBalance = {setAvailableBalance}
  adBudget = {adBudget}
  setAdBudget = {setAdBudget}
  estimatedGST = {estimatedGST}
  setEstimatedGST = {setEstimatedGST}
  totalAmount = {totalAmount}
  setTotalAmount = {setTotalAmount}
      />,
    },
    {
      path: "/s",
      element: <SecondPage 
      Time={Time}
  setTime={setTime}
  status={status}
  setStatus={setStatus}
  Reach={Reach}
  setReach={setReach}
  CostPerPeople={CostPerPeople}
  setCostPerPeople={setCostPerPeople}
  amountSpent = {amountSpent}
  setAmountSpent={setAmountSpent}
  DailyBudegt={DailyBudegt}
  setDailyBudegt={setDailyBudegt}
  Impressions={Impressions}
  setImpressions={setImpressions}
  UniqueClicks={UniqueClicks}
  setUniqueClicks={setUniqueClicks}
  CPC={CPC}
  setCPC={setCPC}
  MessagingConversations={MessagingConversations}
  setMessagingConversations={setMessagingConversations}
  CampaignName={CampaignName}
  setCampaignName={setCampaignName}
  NewAwareness={NewAwareness}
  setNewAwareness={setNewAwareness}
  NewAwarenessAdd={NewAwarenessAdd}
  setNewAwarenessAdd={setNewAwarenessAdd}
  NewAwarenessDes={NewAwarenessDes}
  setNewAwarenessDes={setNewAwarenessDes}
  BudgetSetup={BudgetSetup}
  setBudgetSetup={setBudgetSetup}
  CampaignStatus={CampaignStatus}
  setCampaignStatus={setCampaignStatus}
  Maximum={Maximum}
  setMaximum={setMaximum}
  PurchaseROAS={PurchaseROAS}
  setPurchaseROAS={setPurchaseROAS}
      />,
    },
    {
      path: "/w",
      element: <Website 
      AdsName={AdsName}
  setAdsName={setAdsName}
  AdsStatus={AdsStatus}
  setAdsStatus={setAdsStatus}
  totalAmount = {totalAmount}
  setTotalAmount={setTotalAmount}
  SpendingLimit={SpendingLimit}
  setSpendingLimit={setSpendingLimit}
  NewAwareness={NewAwareness}
  setNewAwareness={setNewAwareness}
  Reach={Reach}
  setReach={setReach}
  PeopleCost={PeopleCost}
  setPeopleCost={setPeopleCost}
  amountSpent = {amountSpent}
  setAmountSpent={setAmountSpent}
  Maximum={Maximum}
  setMaximum={setMaximum}
      />,
    },
    {
      path: "/a",
      element: <Ads 
      AdvertisName={AdvertisName}
  setAdvertisName={setAdvertisName}
  AdvertisPrice={AdvertisPrice}
  setAdvertisPrice={setAdvertisPrice}
  AdvertisCount={AdvertisCount}
  setAdvertisCount={setAdvertisCount}
  AdvertisTime={AdvertisTime}
  setAdvertisTime={setAdvertisTime}
  peopleReach = {peopleReach}
  setPeopleReach={setPeopleReach}
  PostEngagements={PostEngagements}
  setPostEngagements={setPostEngagements}
  LinkClicks={LinkClicks}
  setLinkClicks={setLinkClicks}
  Times={Times}
  setTimes={setTimes}
  status = {status}
  setStatus={setStatus}
  ImgTitle={ImgTitle}
  setImgTitle={setImgTitle}
  ImgDes={ImgDes}
  setImgDes={setImgDes}
  MesssagingConversations={MesssagingConversations}
  setMesssagingConversations={setMesssagingConversations}
  Spent={Spent}
  setSpent={setSpent}
      />,
    },
  ]);
  


  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
