import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ads.css";
import Img36 from "./images/36.png";
import Img37 from "./images/37.png";
import Img38 from "./images/38.png";
import Img39 from "./images/39.png";
import Img40 from "./images/40.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const Ads = ({
  AdvertisName,
  setAdvertisName,
  AdvertisPrice,
  setAdvertisPrice,
  AdvertisCount,
  setAdvertisCount,
  AdvertisTime,
  setAdvertisTime,
  peopleReach,
  setPeopleReach,
  PostEngagements,
  setPostEngagements,
  LinkClicks,
  setLinkClicks,
  Times,
  setTimes,
  status,
  setStatus,
  ImgTitle,
  setImgTitle,
  ImgDes,
  setImgDes,
  MesssagingConversations,
  setMesssagingConversations,
  Spent,
  setSpent,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  useEffect(() => {
    const createBtn = document.getElementById("add_create_box");
    window.addEventListener("scroll", function (e) {
      if (window.scrollY > 200) {
        createBtn.classList.add("add_create_box");
        createBtn.classList.remove("add_create_box_hide");
      } else {
        createBtn.classList.remove("add_create_box");
        createBtn.classList.add("add_create_box_hide");
      }
    });
  }, []);



  return (
    <div className="ads_box">
      <div className="ad_nav">
        <span>
          <ArrowBackIcon />
        </span>
        <span className="ad_nav_text">Ads</span>
      </div>
      <div className="add_create_box_hide" id="add_create_box">
        <div className="add_create_btn">
          <AddOutlinedIcon className="add_create_btn_icon" />
          Create ad
        </div>
      </div>
      <div className="ads_create">
        <div className="ads_box_title">create</div>
        <div className="ads_create_box">
          <div className="ads_create_img_box">
            <img src={Img36} alt="" className="ads_create_img" />
          </div>
          <div className="ads_create_des_box">
            <p className="ads_create_des1">Boosted post</p>
            <p className="ads_create_des2">
              Use your eligible posts, stories and reels to create an ad
            </p>
          </div>
          <div className="ads_create_icon">
            <img src={Img39} alt="" className="ads_create_icon_img" />
          </div>
        </div>
        <div className="ads_create_box">
          <div className="ads_create_img_box">
            <img src={Img37} alt="" className="ads_create_img" />
          </div>
          <div className="ads_create_des_box">
            <p className="ads_create_des1">New ad</p>
            <p className="ads_create_des2">
              Make an ad using text, photos or videos to promote your business
            </p>
          </div>
          <div className="ads_create_icon">
            <img src={Img39} alt="" className="ads_create_icon_img" />
          </div>
        </div>
        <div className="ads_create_box">
          <div className="ads_create_img_box">
            <img src={Img38} alt="" className="ads_create_img" />
          </div>
          <div className="ads_create_des_box">
            <p className="ads_create_des1">Automated Ads</p>
            <p className="ads_create_des2">
              We'll provide recommendations for your ads
            </p>
          </div>
          <div className="ads_create_icon">
            <img src={Img39} alt="" className="ads_create_icon_img" />
          </div>
        </div>
      </div>

      <div className="manage">
        <div className="ads_box_title">manage</div>
        <div className="manage_box">
          <div className="manage_box_title">To do</div>
          <div className="manage_box_des">
            <span className="manage_box_des1">Recommendations</span>
            <span className="manage_box_des2">1</span>
          </div>
        </div>
      </div>

      <div className="advertising">
        <div className="advertising_top">
          <div className="ads_box_title">Advertising Summary</div>
          <ExpandLessOutlinedIcon className="advertising_top_icon" />
        </div>
        <div className="advertising_input_boxs">
          <input
            type="text"
            value={AdvertisName}
            onChange={(e) => {
              setAdvertisName(e.target.value);
              // localStorage.setItem("username");
            }}
            className="advertising_input1"
          />
          spent
          <input
            type="text"
            value={AdvertisPrice}
            onChange={(e) => {
              setAdvertisPrice(e.target.value);
              // localStorage.setItem("amountspent");
            }}
            className="advertising_input2"
          />
          on
          <input
            type="text"
            value={AdvertisCount}
            onChange={(e) => {
              setAdvertisCount(e.target.value);
              // localStorage.setItem("advertiscount");
            }}
            className="advertising_input3"
          />
          ad in the
          <input
            type="text"
            value={AdvertisTime}
            onChange={(e) => {
              setAdvertisTime(e.target.value);
              // localStorage.setItem("advertistime");
            }}
            className="advertising_input4"
          />
          .
        </div>
        <div className="advertising_middle">
          <div className="advertising_middle_box">
            <p className="advertising_middle_box_title">Currently viewing</p>
            <input
              type="text"
              value={AdvertisTime}
              onChange={(e) => {
                setAdvertisTime(e.target.value);
                // localStorage.setItem("advertistime");
              }}
              className="advertising_middle_box_input"
            />
          </div>
          <div className="advertising_middle_icon">
            <img src={Img40} alt="" className="advertising_middle_img" />
          </div>
        </div>

        <div className="advertising_bottom">
          <div className="advertising_bottom_box">
            <p className="advertising_bottom_box_title">People reached</p>
            <input
              type="text"
              value={peopleReach}
              onChange={(e) => {
                setPeopleReach(e.target.value);
                // localStorage.setItem("peoplereach");
              }}
              className="advertising_bottom_box_input"
            />
          </div>
          <div className="advertising_bottom_box">
            <p className="advertising_bottom_box_title">Post Engagements</p>
            <input
              type="text"
              value={PostEngagements}
              onChange={(e) => {
                setPostEngagements(e.target.value);
                // localStorage.setItem("postengagements");
              }}
              className="advertising_bottom_box_input"
            />
          </div>
          <div className="advertising_bottom_box">
            <p className="advertising_bottom_box_title">Link Clicks</p>
            <input
              type="text"
              value={LinkClicks}
              onChange={(e) => {
                setLinkClicks(e.target.value);
                // localStorage.setItem("linkclicks");
              }}
              className="advertising_bottom_box_input"
            />
          </div>
        </div>
      </div>

      <div className="recent_ads">
        <div className="advertising_top">
          <div className="ads_box_title">Recent ads</div>
          <ExpandLessOutlinedIcon className="advertising_top_icon" />
        </div>
        <div className="recent_ads_middle">
          <div className="advertising_middle_left">
            <div className="advertising_middle_left_box1">
              <input
                type="text"
                value={Times}
                onChange={(e) => {
                  setTimes(e.target.value);
                  // localStorage.setItem("time");
                }}
                className="advertising_middle_left_box1_input1"
              />
              <FiberManualRecordIcon className="recent_ads_box_top_icon" />
              <input
                type="text"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  // localStorage.setItem("profilestatus");
                }}
                className="advertising_middle_left_box1_input2"
              />
            </div>
            <input
              type="text"
              value={ImgTitle}
              onChange={(e) => {
                setImgTitle(e.target.value);
                // localStorage.setItem("imgtitle");
              }}
              className="recent_ads_title_input"
            />
            <p className="recent_ads_des">
              <input
                type="text"
                value={ImgDes}
                onChange={(e) => {
                  setImgDes(e.target.value);
                  // localStorage.setItem("imgdes");
                }}
                className="recent_ads_des_input"
              />
            </p>
          </div>
          <div className="advertising_middle_right">
            <div className="SuploadImage">
              <label for="upload" className="SuploadFile">
                {selectedFile ? (
                  <img src={preview} alt="img" />
                ) : (
                  <AttachmentIcon className="SattachmentIcon" />
                )}
                <input
                  type="file"
                  accept="image/jpg,image/jpeg,image/png"
                  id="upload"
                  onChange={onSelectFile}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="advertising_lower_middle">
          <div className="advertising_lower_middle_box">
            <ForumOutlinedIcon className="advertising_lower_middle_box_icon" />
            <div className="advertising_lower_middle_text">
              <input
                type="text"
                value={MesssagingConversations}
                onChange={(e) => {
                  setMesssagingConversations(e.target.value);
                  // localStorage.setItem("masseging");
                }}
                className="advertising_lower_middle_text_input1"
              />
              Messsaging conversations started
            </div>
          </div>
          <div className="advertising_lower_middle_box">
            <AttachMoneyIcon className="advertising_lower_middle_box_icon" />
            <div className="advertising_lower_middle_text">
              <input
                type="text"
                value={Spent}
                onChange={(e) => {
                  setSpent(e.target.value);
                  // localStorage.setItem("amountspent");
                }}
                className="advertising_lower_middle_text_input2"
              />
              Spent
            </div>
          </div>
        </div>

        <div className="recent_ads_bottom">
          <div className="recent_ads_bottom_btn">View results</div>
          <div className="recent_ads_bottom_menu_btn">
            <MoreHorizOutlinedIcon className="recent_ads_bottom_menu_icon" />
          </div>
        </div>
      </div>

      <div className="see_more_ads">See more details in Ads Manager</div>
    </div>
  );
};

export default Ads;
