import React, { useState, useEffect } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CreateIcon from "@mui/icons-material/Create";
import duplicatricon from "./images/duplicateIcon.jpeg";
import metricsicon from "./images/metricsIcon.jpeg";
import viewPerformance from "./images/18.png";
import ClearIcon from "@mui/icons-material/Clear";
import "./secondPage.css";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Img19 from "./images/19.png";
import Img20 from "./images/20.png";
import Img21 from "./images/21.png";
import Img22 from "./images/22.png";
import Img23 from "./images/23.png";
import Img24 from "./images/24.png";
import Img25 from "./images/25.png";

function SecondPage({
  Time,
  setTime,
  status,
  setStatus,
  Reach,
  setReach,
  CostPerPeople,
  setCostPerPeople,
  PurchaseROAS,
  setPurchaseROAS,
  amountSpent,
  setAmountSpent,
  DailyBudegt,
  setDailyBudegt,
  Impressions,
  setImpressions,
  UniqueClicks,
  setUniqueClicks,
  CPC,
  setCPC,
  MessagingConversations,
  setMessagingConversations,
  CampaignName,
  setCampaignName,
  NewAwareness,
  setNewAwareness,
  NewAwarenessAdd,
  setNewAwarenessAdd,
  NewAwarenessDes,
  setNewAwarenessDes,
  BudgetSetup,
  setBudgetSetup,
  CampaignStatus,
  setCampaignStatus,
  Maximum,
  setMaximum,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#1976D2", // Color when the switch is checked
            },
            "&.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#FF5733", // Track color when the switch is checked
            },
          },
        },
      },
    },
  });

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 19,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#1679FF" : "#1976D2",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#1679FF",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      backgroundColor: "#303030",
      width: 14,
      height: 14,
      marginLeft: "2px",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#5c5c5c",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [Btn, setBtn] = useState(false);

  return (
    <>
      <div className="second_nav">
        <p>
          <ClearIcon className="clear_icon" />
        </p>
        <p>
          <img src={Img21} className="icon_img1" alt="Phoneo" />
          <h2 className="top_title">Campaign</h2>
        </p>
        <p>
          <img src={Img19} className="icon_img2" alt="Phoneo" />
          <img src={Img20} className="icon_img3" alt="Phoneo" />
        </p>
      </div>
      <div className="SeconpageContainer">
        <div className="image_switch_editbtn_container">
          <div className="imageuploadContainer">
            <div>
              <div className="SuploadImage">
                <label htmlFor="upload" className="SuploadFile">
                  {selectedFile ? (
                    <img src={preview} alt="img" />
                  ) : (
                    <AttachmentIcon className="SattachmentIcon" />
                  )}
                  <input
                    type="file"
                    accept="image/jpg,image/jpeg,image/png"
                    id="upload"
                    onChange={onSelectFile}
                  />
                </label>
              </div>
              <div style={{ width: "80%" }}>
                <input
                  type="text"
                  className="ad_set_profile_title"
                  value={NewAwareness}
                  onChange={(e) => {
                    setNewAwareness(e.target.value);
                    // localStorage.setItem("newawareness", e.target.value);
                  }}
                />
                <span>
                  <input
                    type="text"
                    value={Time}
                    onChange={(e) => {
                      setTime(e.target.value);
                      // localStorage.setItem("time", e.target.value);
                    }}
                  />
                  {"- "}
                  <input
                    type="text"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      // localStorage.setItem("profilestatus", e.target.value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div>
              <ChevronRightIcon />
            </div>
          </div>
          <div className="off_switch">
            <p className="dot">
              <span>
                <FiberManualRecordIcon
                  style={{ fontSize: "8px", color: "rgb(34, 194, 50)" }}
                />
              </span>
              <span onClick={() => setBtn(!Btn)}>{Btn ? "Active" : "Off"}</span>
            </p>
            <div className="switch">
              <IOSSwitch sx={{ m: 0 }} defaultChecked={false} />
            </div>
          </div>
          <div className="Edit_duplicateBtn">
            <button>
              <span>
                <CreateIcon style={{ width: "16px" }} />
              </span>
              <span>Edit</span>
            </button>
            <button>
              <span>
                <img src={duplicatricon} alt="" style={{ width: "16px" }} />
              </span>
              <span>Duplicate</span>
            </button>
          </div>
        </div>

        <div className="infoContainer">
          <div>
            <button>
              <input
                type="text"
                className="maximum_date"
                value={Maximum}
                onChange={(e) => {
                  setMaximum(e.target.value);
                  // localStorage.setItem("maximum", e.target.value);
                }}
              />
            </button>
            <button>
              <span className="icon_metrics">
                <img src={metricsicon} alt="" style={{ width: "100%" }} />
              </span>
              <span>Metrics</span>
            </button>
          </div>
          <div>Result</div>
          <div>
            <div>
              <p>Reach</p>
              <input
                type="text"
                value={Reach}
                onChange={(e) => {
                  setReach(e.target.value);
                  // localStorage.setItem("peoplereach", e.target.value);
                }}
              />
            </div>
            {/* <div>
              <p>Cost per 1,000 People Reached</p>
              <input
                type="text"
                value={CostPerPeople}
                onChange={(e) => {
                  setCostPerPeople(e.target.value);
                  // localStorage.setItem("peoplecost", e.target.value);
                }}
              />
            </div> */}
            <div>
              <p>Amount spent</p>
              <input
                type="text"
                value={amountSpent}
                onChange={(e) => {
                  setAmountSpent(e.target.value);
                  // localStorage.setItem("amountspent", e.target.value);
                }}
              />
            </div>
            <div id="editBudgut">
              <input
                type="text"
                value={DailyBudegt}
                onChange={(e) => {
                  setDailyBudegt(e.target.value);
                  // localStorage.setItem("dailybudegt", e.target.value);
                }}
                style={{ textAlign: "end", width: "50%",marginRight:'3px' }}
              />
               daily budget&nbsp;
              <CreateIcon style={{ width: "12px" }} />
            </div>
            {/* <div>
              <p>Reach</p>
              <input type="text" value={ReachNo} onChange={(e)=>setReachNo(e.target.value)}/>
            </div> */}
            <div>
              <p>Impressions</p>
              <input
                type="text"
                value={Impressions}
                onChange={(e) => {
                  setImpressions(e.target.value);
                  // localStorage.setItem("impressions", e.target.value);
                }}
              />
            </div>
            <div>
              <p>Purchase ROAS (return on ad spend)</p>
              <input type="text" value={PurchaseROAS} onChange={(e)=>setPurchaseROAS(e.target.value)} />
            </div>
            {/* <div>
              <p>Frequency</p>
              <input type="text" value={Frequency} onChange={(e)=>setFrequency(e.target.value)} />
            </div> */}
            <div>
              <p>Unique clicks (all)</p>
              <input
                type="text"
                value={UniqueClicks}
                onChange={(e) => {
                  setUniqueClicks(e.target.value);
                  // localStorage.setItem("uniqueclicks", e.target.value);
                }}
              />
            </div>
            <div>
              <p>CPC (cost per link click)</p>
              <input
                type="text"
                value={CPC}
                onChange={(e) => {
                  setCPC(e.target.value);
                  // localStorage.setItem("CPC", e.target.value);
                }}
              />
            </div>
            <div>
              <p>Messaging conversations started</p>
              <input
                type="text"
                value={MessagingConversations}
                onChange={(e) => {
                  setMessagingConversations(e.target.value);
                  // localStorage.setItem("masseging", e.target.value);
                }}
              />
            </div>
            <div className="see_btn">
              {/* <div className="see"> <ExpandMoreIcon className="see_icon"/>  See more</div> */}
              <div className="see">
                {" "}
                <ExpandLessIcon className="see_icon" /> Show less
              </div>
            </div>
          </div>
          <div className="view_btn">
            <img className="view_btn_img" src={viewPerformance} alt="" /> View
            performance
          </div>
        </div>
        <div className="second_setup">
          <div className="setup">
            <h3 className="setup_title">setup</h3>
            <div className="setup_box">
              <img src={Img22} className="setup_img" alt="" />
              <div className="setup_text_box">
                <input
                  type="text"
                  className="setup_text1"
                  value={CampaignName}
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                    // localStorage.setItem("campaignname", e.target.value);
                  }}
                />
                <input
                  type="text"
                  className="setup_text2"
                  value={NewAwareness}
                  onChange={(e) => {
                    setNewAwareness(e.target.value);
                    // localStorage.setItem("newawareness", e.target.value);
                  }}
                />
              </div>
              <img src={Img24} className="setup_img" alt="" />
            </div>
            <div className="setup_box">
              <img src={Img23} className="setup_img" alt="" />
              <div className="setup_text_box">
                <input type="text" className="setup_text1" value={"Budget"} />
                <p className="setup_text2_box">
                  <input
                    type="text"
                    className="setup_text3"
                    value={BudgetSetup}
                    onChange={(e) => {
                      setBudgetSetup(e.target.value);
                      // localStorage.setItem("adbudget", e.target.value);
                    }}
                  />
                  INR per day, Bid cap
                </p>
              </div>
              <img src={Img24} className="setup_img" alt="" />
            </div>
            <div className="see_btn">
              <div className="setup_see">
                {" "}
                <ExpandMoreIcon className="see_icon" /> Show more
              </div>
            </div>
          </div>
        </div>

        <div className="ad_set">
          <div className="ad_set_box">
            <div className="ad_set_box_title">
              <h3 className="ad_set_title_name">Ad set</h3>
              <img src={Img20} alt="" className="ad_set_box_img" />
            </div>
            <div className="ad_set_dot">
              <span>
                <FiberManualRecordIcon
                  sx={{ fontSize: "8px", color: "rgb(34, 194, 50)" }}
                />
              </span>
              <input
                type="text"
                value={CampaignStatus}
                onChange={(e) => {
                  setCampaignStatus(e.target.value);
                  // localStorage("campaignstatus", e.target.value);
                }}
                className="ad_set_dot_input"
              />
            </div>
            <div className="ad_set_input_box">
              <input
                type="text"
                className="ad_set_text1"
                value={CampaignName}
                onChange={(e) => {
                  setCampaignName(e.target.value);
                  // localStorage.setItem("campaignname", e.target.value);
                }}
              />
              <input
                type="text"
                className="ad_set_text2"
                value={NewAwareness}
                onChange={(e) => {
                  setNewAwareness(e.target.value);
                  // localStorage.setItem("newawareness", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="ad_set_box">
            <div className="ad_set_box_title">
              <h3 className="ad_set_title_name">Ad</h3>
              <img src={Img20} alt="" className="ad_set_box_img" />
            </div>
            <div className="ad_box">
              <div className="SuploadImage">
                <label htmlFor="upload" className="SuploadFile">
                  {selectedFile ? (
                    <img src={preview} alt="img" />
                  ) : (
                    <AttachmentIcon className="SattachmentIcon" />
                  )}
                  <input
                    type="file"
                    accept="image/jpg,image/jpeg,image/png"
                    id="upload"
                    onChange={onSelectFile}
                  />
                </label>
              </div>

              <div style={{ width: "80%" }}>
                <div className="ad_set_dot">
                  <span>
                    <FiberManualRecordIcon
                      sx={{ fontSize: "8px", color: "rgb(34, 194, 50)" }}
                    />
                  </span>
                  <input
                    type="text"
                    value={CampaignStatus}
                    onChange={(e) => {
                      setCampaignStatus(e.target.value);
                      // localStorage("campaignstatus", e.target.value);
                    }}
                    className="ad_set_dot_input"
                  />
                </div>
                <div className="ad_set_input_box">
                  <input
                    type="text"
                    className="ad_set_text1"
                    value={NewAwarenessAdd}
                    onChange={(e) => {
                      setNewAwarenessAdd(e.target.value);
                      // localStorage.setItem("newawarenessadd", e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    className="ad_set_text2"
                    value={NewAwarenessDes}
                    onChange={(e) => {
                      setNewAwarenessDes(e.target.value);
                      // localStorage.setItem("newawarenessdes", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ad_preview_btn">
              <img src={Img25} alt="" className="ad_preview_icon_img" />
              <span style={{ marginTop: "3px" }}>Preview Ad</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondPage;
